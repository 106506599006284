import React from "react"
import Layout from "../components/layout"

//import "../fontawesome/css/all.min.css"
import "./index.css"
// import logo from "../images/general-PCS-Logo-300wide.png"

  const GenInfo = () => (
  <div className="App">
    <Layout>

        <div className="container">


      <div className="row">
      <div className="col-md-12">
      <h1>2024 - 20th Annual Pardeeville Community Car &amp; Truck Show</h1>

      </div>
      </div>

      <div className="row">

      <div className="col-md-6">

      <p>
      <time dateTime="2024-08-31 08:00">Saturday, August 31st 2024 8:00AM</time>{" "}
      – 4:00PM&nbsp; (Pardeeville, WI) Brought to you by the PCCTS, Inc.
      </p>
      <address>
      <a href="https://www.google.com/maps/place/Chandler+Park+Dr,+Pardeeville,+WI+53954/@43.5402539,-89.2975575,17z/data=!3m1!4b1!4m2!3m1!1s0x8806dc6822caefcd:0xdde15e76316130fc">
      Chandler Park, Chandler Park Dr, Pardeeville, WI 53954
      </a>
      </address>
      <ul>
      <li>Lots of cars</li>
      <li>Vendor booths&nbsp;</li>
      <li>
        Food and refreshements (brats, hot dogs, hamburgers, homemade pies,
        sweet corn, cookies, ice cream coffee, water and soda)
      </li>
      <li>Silent auction (located by the tennis court)</li>
      <li>Pardeeville Car Show t-shirts</li>
      <li>Admission free (donations accepted)</li>
      <li>
        Canned food and non-perishable donations (donated to the Grace
        Presbyterian Food Pantry)
      </li>
      <li>
        Tractor Show on Grounds
        <br /> (Opposite side of cars in the park)
      </li>
      <li>Playground for kids</li>

      </ul>
      <p>
      PCCTS, INC. is not responsible for any injuries, damages or accidents
      incurred to attendees or vehicles while at the show.{" "}
      </p>
      <div>
      <h4>Miscellaneous Details</h4>
      </div>
      <div>
      <ul>
      <li>Alcohol-Free Event for your whole family</li>
      <li>DoorPrizes throughout the day</li>
      <li>Fishing &amp; Beach on grounds</li>
      <li>Children’sGames &amp; Activities in the park</li>
      <li>Playgroundin the park</li>
      <li>Silent Auction</li>
      <li>Vendor Booths</li>
      <li>Picnic Tables available</li>
      <li>Remember your lawn chairs</li>
      <li>Security on grounds during show</li>
      <li>No Carry-Ins Please</li>
      <li>
        Bring your canopy for protection from the sun (some cars will be on
        the football field this year w/o trees) <br/>NOTE:
        ALL CANOPIES MUST BE SECURED DOWN FOR SAFETY OF OTHERS. YOU WILL BE RESPONSIBLE FOR ANY INCIDENT REGARDING YUR CANOPY.
      </li>
      </ul>
      </div>
      </div>
      <div className="col-md-6">
      <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2892.181715384891!2d-89.29755748450589!3d43.54025387912532!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8806dc6822caefcd%3A0xdde15e76316130fc!2sChandler+Park+Dr%2C+Pardeeville%2C+WI+53954!5e0!3m2!1sen!2sus!4v1558615681343"
      width={500}
      height={550}
      frameBorder={0}
      style={{ border: 0 }}
      allowFullScreen
      title="Map"
      />
      </div>
      </div>
      <div className="row">

      <div className="col-md-12">
      <h3>See us from the Air</h3>
      <div className="embed-responsive embed-responsive-16by9">
      <iframe
      src="https://player.vimeo.com/video/141426769?title=0&byline=0&portrait=0"
      width={640}
      height={360}
      frameBorder={0}
      allow="autoplay; fullscreen"
      allowFullScreen
      title="Video"
      />
      </div>
      <p> &nbsp;</p>
      </div>
      </div>

      </div>
    </Layout>
  </div>
)
export default GenInfo
